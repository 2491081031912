<template>
  <SessionChartWrapper>
    <sdCards :title="title">
      <div class="session-chart-inner">
        <div :style="{ position: 'relative' }">
          <p>
            <span>{{
              [
                {
                  data: cData,
                  backgroundColor: colors,
                },
              ][0].data.reduce((a, b) => a + b, 0)
            }}</span>
            {{totalTitle}}
          </p>
          <Chart
            :class="displayClass"
            type="doughnut"
            :labels="dataLabels"
            :datasets="[
              {
                data: cData,
                backgroundColor: colors,
              },
            ]"
            :options="{
              cutoutPercentage: 70,
              maintainAspectRatio: true,
              responsive: true,
              legend: {
                display: false,
                position: 'bottom',
              },
              animation: {
                animateScale: true,
                animateRotate: true,
              },
            }"
          />
        </div>
        <SessionState class="session-wrap d-flex justify-content-center">
          <div
            class="session-single"
            v-for="(device, index) of chartData"
            :key="device.name"
          >
            <div class="chart-label">
              <span class="label-dot" :style="{background: colors[index]}" />
              {{ device.name}} 
            </div>
            <span>{{ device.count }}</span>
            <sub>{{ device.percentage }}%</sub>
          </div>
        </SessionState>
      </div>
    </sdCards>
  </SessionChartWrapper>
</template>
<script>
import VueTypes from 'vue-types';
import { SessionChartWrapper, SessionState } from '../DoughnutChart/style';
import Chart from '@/components/utilities/chartjs.vue';

export default {
  name: 'DoughnutChart',
  components: { SessionChartWrapper, SessionState, Chart },
  props: {
    totalTitle : VueTypes.string.def(''),
    title: VueTypes.string.def(''),
    displayClass: VueTypes.string.def(''),
    chartHeight: VueTypes.number.def(120),
    colors: VueTypes.array.def([]),
    chartData: VueTypes.array.def([]),
  },
  data() {
    return {
      dataState: [],
      dataLabels: [],
    };
  },
  watch: {
    chartData: function(newData) {
      this.cData = newData.map(d => d.count);
      this.dataLabels = newData.map(d => d.name);
    }
  },
  created() {
    this.cData = this.chartData.map(d => d.count);
    this.dataLabels = this.chartData.map(d => d.name);
  }
};
</script>
